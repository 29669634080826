// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-body-body-js": () => import("./../../../src/pages/home/Body/Body.js" /* webpackChunkName: "component---src-pages-home-body-body-js" */),
  "component---src-pages-home-body-card-js": () => import("./../../../src/pages/home/Body/Card.js" /* webpackChunkName: "component---src-pages-home-body-card-js" */),
  "component---src-pages-home-body-index-js": () => import("./../../../src/pages/home/Body/index.js" /* webpackChunkName: "component---src-pages-home-body-index-js" */),
  "component---src-pages-home-body-styles-js": () => import("./../../../src/pages/home/Body/styles.js" /* webpackChunkName: "component---src-pages-home-body-styles-js" */),
  "component---src-pages-home-footer-footer-js": () => import("./../../../src/pages/home/Footer/Footer.js" /* webpackChunkName: "component---src-pages-home-footer-footer-js" */),
  "component---src-pages-home-footer-index-js": () => import("./../../../src/pages/home/Footer/index.js" /* webpackChunkName: "component---src-pages-home-footer-index-js" */),
  "component---src-pages-home-footer-styles-js": () => import("./../../../src/pages/home/Footer/styles.js" /* webpackChunkName: "component---src-pages-home-footer-styles-js" */),
  "component---src-pages-home-header-face-badge-js": () => import("./../../../src/pages/home/Header/FaceBadge.js" /* webpackChunkName: "component---src-pages-home-header-face-badge-js" */),
  "component---src-pages-home-header-greeting-js": () => import("./../../../src/pages/home/Header/Greeting.js" /* webpackChunkName: "component---src-pages-home-header-greeting-js" */),
  "component---src-pages-home-header-header-js": () => import("./../../../src/pages/home/Header/Header.js" /* webpackChunkName: "component---src-pages-home-header-header-js" */),
  "component---src-pages-home-header-index-js": () => import("./../../../src/pages/home/Header/index.js" /* webpackChunkName: "component---src-pages-home-header-index-js" */),
  "component---src-pages-home-header-info-buttons-js": () => import("./../../../src/pages/home/Header/InfoButtons.js" /* webpackChunkName: "component---src-pages-home-header-info-buttons-js" */),
  "component---src-pages-home-header-styles-js": () => import("./../../../src/pages/home/Header/styles.js" /* webpackChunkName: "component---src-pages-home-header-styles-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-talkspace-index-js": () => import("./../../../src/pages/talkspace/index.js" /* webpackChunkName: "component---src-pages-talkspace-index-js" */),
  "component---src-pages-talkspace-sections-abstract-js": () => import("./../../../src/pages/talkspace/sections/Abstract.js" /* webpackChunkName: "component---src-pages-talkspace-sections-abstract-js" */),
  "component---src-pages-talkspace-sections-conclusion-js": () => import("./../../../src/pages/talkspace/sections/Conclusion.js" /* webpackChunkName: "component---src-pages-talkspace-sections-conclusion-js" */),
  "component---src-pages-talkspace-sections-exploring-my-data-js": () => import("./../../../src/pages/talkspace/sections/ExploringMyData.js" /* webpackChunkName: "component---src-pages-talkspace-sections-exploring-my-data-js" */),
  "component---src-pages-talkspace-sections-getting-the-data-js": () => import("./../../../src/pages/talkspace/sections/GettingTheData.js" /* webpackChunkName: "component---src-pages-talkspace-sections-getting-the-data-js" */),
  "component---src-pages-talkspace-sections-index-js": () => import("./../../../src/pages/talkspace/sections/index.js" /* webpackChunkName: "component---src-pages-talkspace-sections-index-js" */),
  "component---src-pages-talkspace-sections-introduction-js": () => import("./../../../src/pages/talkspace/sections/Introduction.js" /* webpackChunkName: "component---src-pages-talkspace-sections-introduction-js" */),
  "component---src-pages-talkspace-sections-regression-analysis-js": () => import("./../../../src/pages/talkspace/sections/RegressionAnalysis.js" /* webpackChunkName: "component---src-pages-talkspace-sections-regression-analysis-js" */),
  "component---src-pages-talkspace-talkspace-js": () => import("./../../../src/pages/talkspace/Talkspace.js" /* webpackChunkName: "component---src-pages-talkspace-talkspace-js" */)
}

